import React from 'react';
import Section from './../../../components/Screen/Section';
import classes from './style.module.scss';

const HeroUnit = (props) => {
    return <Section nopadding={true}>
        <div className={classes.HeroUnit}>
            <div className={classes.content}>
                <h2>SharedLearning</h2>
                <h4>Sub heading goes here</h4>
            </div>
        </div>
    </Section>;
}

export default HeroUnit;