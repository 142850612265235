import React from 'react';
import classes from './style.module.scss';

const Section = (props) => {
    let sectionClasses = [classes.Section];
    if(props.variant !== undefined && props.variant === "alternate")
    {
        sectionClasses.push(classes.Alternate);
    }

    if(props.nopadding === true)
    {
        sectionClasses.push(classes.NoPadding);
    }
    
    return <section className={sectionClasses.join(' ')}>
        {props.heading !== undefined ? <h2>{props.heading}</h2> : null}
        {props.children}
    </section>
}

export default Section;