import React, {Component, Fragment} from 'react';
import axios from 'axios';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';

import remote from './../../../remote';
import classes from './style.module.scss';

class LastQOTDWinners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            top3: null,
            items: [],
            loadMore: true,
            open: false
        };
    }

    componentDidMount() {
        axios(remote.base + '/qotd/last/winners', {
            method: 'GET',
            headers: {
                'authKey': remote.authKey,
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if(response.data.top3 !== undefined)
            {
                this.setState((state, props) => {
                    return {
                        top3: response.data.top3
                    }
                });
            }
        });
    }

    loadAllWinners() {
        axios(remote.base + '/qotd/last/winners', {
            method: 'GET',
            headers: {
                'authKey': remote.authKey,
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if(response.data.top3 !== undefined)
            {
                this.setState((state, props) => {
                    return {
                        top3: response.data.top3
                    }
                });
            }
        });
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    handleOpen() {
        this.setState({
            open: true
        });
    }

    render() {
        let yesterdayQOTDWinners = this.state.top3 !== null ? <Card style={{height: '100%'}}>
        <CardContent>
            <Typography gutterBottom>
                Last QOTD Winners
            </Typography>

            <List>
                {this.state.top3.map((user, index) => <ListItem key={index}>
                    <ListItemAvatar>
                    <Avatar src={user.image_url} alt={user.name} />
                    </ListItemAvatar>
                    <ListItemText primary={user.name} />
                </ListItem> )}
            </List>
            
        </CardContent>
        {false && <CardActions style={{justifyContent:'center'}}>
            <Button color="primary" size="small" onClick={this.handleOpen.bind(this)}>Load More</Button>
        </CardActions>}
    </Card> : <Card>
                <CardContent>
                <Typography gutterBottom>
                    Last QOTD Winners
                </Typography>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                        <Skeleton variant="circle" width={40} height={40} />
                        </ListItemAvatar>
                        <ListItemText><Skeleton variant="text" /></ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                        <Skeleton variant="circle" width={40} height={40} />
                        </ListItemAvatar>
                        <ListItemText><Skeleton variant="text" /></ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                        <Skeleton variant="circle" width={40} height={40} />
                        </ListItemAvatar>
                        <ListItemText><Skeleton variant="text" /></ListItemText>
                    </ListItem>
                </List>
                </CardContent>
            
            </Card>;

            let allWinners = this.state.items.length > 0 ? <div>
                <List>
                {this.state.items.map((user, index) => <ListItem key={index}>
                    <ListItemAvatar>
                    <Avatar src={user.image_url} alt={user.name} />
                    </ListItemAvatar>
                    <ListItemText primary={user.name} />
                </ListItem> )}
            </List>
            </div>
            : <div style={{textAlign:'center'}}>
                <CircularProgress />
            </div>;

            return <Fragment>
                {yesterdayQOTDWinners}
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose.bind(this)}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
                    <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        {allWinners}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleClose.bind(this)} color="primary">
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>;
    }
}

export default LastQOTDWinners;