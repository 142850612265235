import React, {Component} from 'react';
import axios from 'axios';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

import remote from './../../../remote';
import Section from './../../../components/Screen/Section';

import classes from './style.module.scss';

class DailyPrep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dailyPrepFeatured: null
        };
    }

    componentDidMount() {
        // DailyPrep Featured
        axios(remote.base + '/dailyprep/featured', {
            method: 'GET',
            headers: {
                'authKey': remote.authKey,
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if(response.data.featuredList !== undefined)
            {
                this.setState((state, props) => {
                    return {
                        dailyPrepFeatured: response.data.featuredList
                    }
                });
            }
        });
    }

    handleChange = (event) => {
        this.setState((state, props) => ({
            value: event.target.value
        }));
      };

    render() {
        
        let dailyPrepFeatured = this.state.dailyPrepFeatured !== null ? <Grid container spacing={2}>
        {this.state.dailyPrepFeatured.map((article, index) => <Grid key={index} item xs={12} sm={6}>
            <Card>
                <CardMedia
                    image={article.imageUrl}
                    title={article.title}
                    className={classes.thumbnail}
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {article.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {article.summary}
                    </Typography>
                    </CardContent>
                <CardActions>
                    <Button component="a" href={article.url} target="_blank" size="small" color="primary">
                    View Articles
                    </Button>
                </CardActions>
            </Card>
        </Grid>)}
        
    </Grid>: <LinearProgress />;

        return <Section heading="DailyPrep">
            <Container maxWidth="md">
                {dailyPrepFeatured}
            </Container>
            
<br />
            
            
            
        </Section>;
    }
}

export default DailyPrep;