import React, {Component} from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

import Question from './../../Question';

import remote from './../../../remote';
import classes from './style.module.scss';

class QuestionBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {
        axios(remote.base + '/quesbank', {
            method: 'GET',
            params: {
                catId: 0,
                limit: 3,
                offset: 0
            },
            headers: {
                'authKey': remote.authKey,
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if(response.data.questions !== undefined)
            {
                this.setState((state, props) => {
                    return {
                        items: response.data.questions
                    }
                });
            }
        });
    }

    render() {
        let questionBank = this.state.items.length > 0 ? <Card>
        <CardContent>
            <Typography gutterBottom>
            Question Bank
            </Typography>

                {this.state.items.map((item, index) => <Question key={index} item={item} />)}
            
        </CardContent>

        <CardActions style={{justifyContent:'center'}}>
                <Button component={Link} to="/question-bank" color="primary" size="small">View More</Button>
            </CardActions>
    
    </Card> : <Card>
                <CardContent>
                <Typography gutterBottom>
                Question Bank
                </Typography>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                </CardContent>
            
            </Card>;

        return questionBank;
    }
}

export default QuestionBank;