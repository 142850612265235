import React from 'react';
import { withRouter } from 'react-router-dom'
import classes from './style.module.scss';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const Navigation = (props) => {
    const handleClick = (to) => {
      props.history.push(to);
      props.onItemClick();
    };

    return <List className={classes.List}>
            {props.items.map((item) => {
                return <ListItem button key={item.key} onClick={handleClick.bind(this, item.to)}>
                <ListItemText  primary={item.label} />
              </ListItem>
            })}
        </List>;
}

export default withRouter(Navigation);