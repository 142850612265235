import React, {Component} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            helperText: null,
            error: false
        }
    }
    handleAnswerChange(e) {
        if(parseInt(e.target.value) === this.props.item.ans)
        {
            this.setState((state, props) => {
                return {
                    helperText: `Awesome! that's correct`,
                    error: false
                }
            });
        }
        else
        {
            this.setState((state, props) => {
                return {
                    helperText: 'That is incorrect',
                    error: true
                }
            });
        }
    }

    render() {
        return <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
        <Typography>{this.props.item.ques}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <FormControl component="fieldset" error={this.state.error}>
            <FormHelperText>{this.state.helperText}</FormHelperText>
            <RadioGroup aria-label="answer" name={`answer-${this.props.id}`} onChange={this.handleAnswerChange.bind(this)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel value="1" control={<Radio />} label={`A. ${this.props.item.opt1}`} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel value="2" control={<Radio />} label={`B. ${this.props.item.opt2}`} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel value="3" control={<Radio />} label={`C. ${this.props.item.opt3}`} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel value="4" control={<Radio />} label={`D. ${this.props.item.opt4}`} />
                    </Grid>
                </Grid>
            </RadioGroup>
        </FormControl>
        </AccordionDetails>
    </Accordion>;
    }
}

export default Question;