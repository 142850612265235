import React, {Component} from 'react';
import axios from 'axios';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import remote from './../../../remote';
import classes from './style.module.scss';

class LastQOTD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: null,
            answer: null
        };
    }

    componentDidMount() {
        axios(remote.base + '/qotd/last/answer', {
            method: 'GET',
            headers: {
                'authKey': remote.authKey,
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if(response.data.question !== undefined)
            {
                this.setState((state, props) => {
                    return {
                        question: response.data.question
                    }
                });
            }

            if(response.data.answer !== undefined)
            {
                this.setState((state, props) => {
                    return {
                        answer: response.data.answer
                    }
                });
            }
        });
    }

    render() {
        let yesterdayQOTDQuestion = this.state.question !== null ? <Card style={{height: '100%'}}>
        <CardContent>
            <Typography gutterBottom>
            Yesterday's QOTD
            </Typography>
            <Typography variant="h6" component="h2">
            {this.state.question}
            </Typography>
            <Typography gutterBottom>
            Correct Answer:
            </Typography>
            <Typography variant="h6" component="h2">
            {this.state.answer}
            </Typography>
        </CardContent>
    
    </Card> : <Card>
                <CardContent>
                <Typography gutterBottom>
                Yesterday's QOTD
                </Typography>
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={158} />
                <Skeleton variant="text" />
                </CardContent>
            
            </Card>;

        return yesterdayQOTDQuestion;
    }
}

export default LastQOTD;