import React, {Component} from 'react';

class About extends Component {
    render() {
        return <div>
            About website goes here
        </div>;
    }
}

export default About;