import React, {Component} from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Section from '../../components/Screen/Section';
import Question from '../../components/Question';
import remote from './../../remote';

class QuestionBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoading: false,
            hasMore: true
        };
    }

    componentDidMount() {
        this.loadArticles();
    }

    loadArticles() {
        this.setState((state, props) => ({isLoading: true}));
        axios(remote.base + '/quesbank', {
            method: 'GET',
            params: {
                catId: 0,
                limit: 20,
                offset: this.state.items.length
            },
            headers: {
                'authKey': remote.authKey,
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if(response.data.questions !== undefined)
            {
                this.setState((state, props) => ({
                    items: [...state.items, ...response.data.questions],
                    isLoading: false
                }));
            }

            if(response.data.hasMore !== undefined)
            {
                this.setState((state, props) => ({
                    hasMore: response.data.hasMore
                }));
            }
            else
            {
                this.setState({
                    hasMore: false
                })
            }
        });
    }

    handleLoadMoreClick() {
        console.log('loading');
        this.loadArticles();
    }

    render() {
        let questions = this.state.items.length > 0 
            ? this.state.items.map((item, index) => <Question key={index} item={item} />)
            : null;

        return <Section heading="Question Bank">
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        
                    {questions}

                    </Grid>
                    {this.state.hasMore ? <Grid item xs={12} style={{textAlign:'center'}}>
                        {this.state.isLoading ? <CircularProgress /> : <Button variant="contained" color="primary" onClick={this.handleLoadMoreClick.bind(this)}>Load More</Button>}
                    </Grid> : null }
                </Grid>
            </Container>
        </Section>;
    }
}

export default QuestionBank;