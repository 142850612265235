import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import Navigation from '../Navigation';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: '80%'
    }
}));

const Header = (props) => {
    const [state, setState] = React.useState({
        open: false
    });

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, open: open});
    } 

    const muiClasses = useStyles();
    return <Fragment>
        <AppBar position="fixed">
        <Toolbar>
          <IconButton 
            edge="start" 
            className={muiClasses.menuButton} 
            color="inherit" 
            aria-label="menu" 
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={muiClasses.title}>
            <Link to="/" style={{color: 'white', textDecoration: 'none'}}>SharedLearning</Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
            open={state.open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <AppBar position="static">
              <Toolbar>
                <IconButton 
                  edge="start" 
                  className={muiClasses.menuButton} 
                  color="inherit" 
                  aria-label="menu" 
                  onClick={toggleDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={muiClasses.title}>
                  SharedLearning
                </Typography>
              </Toolbar>
            </AppBar>
            <Navigation onItemClick={toggleDrawer(false)} items={props.navigationItems}>

            </Navigation>
          </SwipeableDrawer>
    </Fragment>;
}

export default Header;