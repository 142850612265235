import React, {Component} from 'react';
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Section from '../../../components/Screen/Section';
import QOTD from '../../../components/widgets/QOTD';
import LastQOTD from '../../../components/widgets/LastQOTD';
import LastQOTDWinners from '../../../components/widgets/LastQOTDWinners';
import QuestionBank from '../../../components/widgets/QuestionBank';

import classes from './style.module.scss';
import { Typography } from '@material-ui/core';

class QuestionOfTheDay extends Component {
    render() {
        return <Section heading="Question of The Day" variant="alternate">
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <QOTD />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LastQOTD />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LastQOTDWinners />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <QuestionBank />
                    </Grid>
                </Grid>
            </Container>
        </Section>;
    }
}

export default QuestionOfTheDay;