import React, {Component, Fragment} from 'react';
import axios from 'axios';



import HeroUnit from './HeroUnit';
import QuestionOfTheDay from './QuestionOfTheDay';
import DailyPrep from './DailyPrep';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }
    componentDidMount() {
        axios.get('/test.json').then((response) => {
            if(response.data.items !== undefined)
            {
                this.setState({
                    items: response.data.items
                });
            }
        });
    }

    render() {
        return <Fragment>
            <HeroUnit />
            <QuestionOfTheDay />
            <DailyPrep />
        </Fragment>;
    }
}

export default Home;