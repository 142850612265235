import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { 
    unstable_createMuiStrictModeTheme as createMuiTheme, 
    ThemeProvider 
} from '@material-ui/core/styles';

import Screen from '../Screen';
import Home from './../../screens/Home';
import About from './../../screens/About';
import QuestionBank from './../../screens/QuestionBank';

import './../../assets/styles/global.scss';

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#034585',
        },
        secondary: {
            main: '#ff8f00',
        }
    }
  });

class App extends Component {
    render() {
        return <Router>
        <Screen navigationItems={[
{ key: 'home', to: '/', label:'Home'},
{ key: 'about', to: '/about', label:'About'},
{ key: 'question-bank', to: '/question-bank', label:'Question Bank'}
]}>
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/question-bank">
                    <QuestionBank />
                </Route>
                <Route path="*"><h1>Nothing found!</h1></Route>
            </Switch>
        </Screen>
    </Router>;
    }
}

export default function CustomStyles(props) {
    return (
      <ThemeProvider theme={theme}>
        <App {...props} />
      </ThemeProvider>
    );
  }