import React from 'react';
import Header from './../Header';
import classes from './style.module.scss';

const Screen = (props) => {
    return <article className={classes.Screen}>
        <Header navigationItems={props.navigationItems} />
        {props.children}
    </article>;
}

export default Screen;