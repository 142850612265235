import React, {Component} from 'react';
import axios from 'axios';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import ScheduleIcon from '@material-ui/icons/Schedule';

import remote from './../../../remote';
import classes from './style.module.scss';

class QOTD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: null,
            options: [],
            fbUrl: null
        };
    }

    componentDidMount() {
        axios(remote.base + '/qotd', {
            method: 'GET',
            headers: {
                'authKey': remote.authKey,
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            if(response.data.isPosted === false)
            {
                this.setState((state, props) => {
                    return {
                        question: false
                    }
                });
                return;
            }

            if(response.data.fbUrl !== undefined)
            {
                this.setState((state, props) => {
                    return {
                        fbUrl: response.data.fbUrl
                    }
                });
            }

            console.log(response.data.question);

            if(response.data.question !== undefined)
            {
                this.setState((state, props) => {
                    return {
                        question: response.data.question
                    }
                });
            }

            if(
                response.data.opt1 !== undefined && 
                response.data.opt2 !== undefined && 
                response.data.opt3 !== undefined && 
                response.data.opt4 !== undefined
            )
            {
                this.setState((state, props) => {
                    return {
                        options: [
                            response.data.opt1,
                            response.data.opt2,
                            response.data.opt3,
                            response.data.opt4
                        ]
                    }
                });
            }
        });
    }

    render() {
        let qOTDOptions = this.state.options.length > 0 ? this.state.options.map((option, index) => <li key={index}>{option}</li>) : null;
        let qOTDQuestion = this.state.question !== null 
            ? (this.state.question === false ? <Card>
                <CardContent>
                    <div className={classes.NotPostedYet}>
                        <ScheduleIcon className={classes.NotPostedYetIcon} />
                        <Typography variant="h6" component="h2">
                        Will be posted soon at 9 PM IST
                        </Typography>
                    </div>
                </CardContent>
            </Card> : <Card>
            <CardContent>
                <Typography variant="h6" component="h2">
                {this.state.question}
                </Typography>
                <ul className={classes.options}>
                    {qOTDOptions}
                </ul>
            </CardContent>
            <CardActions style={{justifyContent:'center'}}>
                <Button component="a" href={this.state.fbUrl} target="_blank" variant="contained" color="primary" size="small">Answer On Facebook</Button>
            </CardActions>
        </Card>)
            : <Card>
            <CardContent>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="rect" width={210} height={158} />
            </CardContent>
        </Card>;

        return qOTDQuestion;
    }
}

export default QOTD;